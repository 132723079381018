var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitMethod($event)
        }
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          autofocus: "",
          label: "Name",
          rules: [
            function(v) {
              return !!v || "Name is required"
            }
          ]
        },
        model: {
          value: _vm.editedName,
          callback: function($$v) {
            _vm.editedName = $$v
          },
          expression: "editedName"
        }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          ref: "dateTextField",
                          attrs: {
                            required: "",
                            label: "Date",
                            "prepend-icon": "fa-calendar",
                            readonly: "",
                            rules: [
                              function(v) {
                                return !!v || "Date is required"
                              }
                            ]
                          },
                          model: {
                            value: _vm.dateDisplay,
                            callback: function($$v) {
                              _vm.dateDisplay = $$v
                            },
                            expression: "dateDisplay"
                          }
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.datepicker,
            callback: function($$v) {
              _vm.datepicker = $$v
            },
            expression: "datepicker"
          }
        },
        [
          _c("v-date-picker", {
            on: { input: _vm.datePicked },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }