import { render, staticRenderFns } from "./name-date-editor.vue?vue&type=template&id=1fc10dd6&"
import script from "./name-date-editor.vue?vue&type=script&lang=js&"
export * from "./name-date-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDatePicker,VForm,VMenu,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fc10dd6')) {
      api.createRecord('1fc10dd6', component.options)
    } else {
      api.reload('1fc10dd6', component.options)
    }
    module.hot.accept("./name-date-editor.vue?vue&type=template&id=1fc10dd6&", function () {
      api.rerender('1fc10dd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/editors/name-date-editor.vue"
export default component.exports