<template>
  <v-form @submit.prevent="submitMethod">
    <v-text-field
      autofocus
      v-model="editedName"
      label="Name"
      :rules="[v => !!v || 'Name is required']"
    />
    <v-menu
      v-model="datepicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          ref="dateTextField"
          required
          v-model="dateDisplay"
          label="Date"
          prepend-icon="fa-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="[v => !!v || 'Date is required']"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="datePicked"
      ></v-date-picker>
    </v-menu>
  </v-form>
</template>

<script>
import { phpDateFormatToMoment } from '@/utils/formatting/dates';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(dayjsUTC);

export default {
  name: 'NameDateEditor',

  props: {
    dateFormat: String,
    proposalDate: String,
    proposalName: String,
  },

  data: function() {
    return {
      datepicker: false,
      editedDate: this.proposalDate,
      editedName: this.proposalName,
    };
  },

  computed: {
    saveBtnEnabled() {
      const nameChanged = this.proposalName !== this.editedName;
      const dateChanged = this.proposalDate !== this.editedDate;
      return (nameChanged || dateChanged) && this.editedName !== '';
    },

    dateDisplay() {
      if ('' === this.editedDate) {
        return '';
      }

      return dayjs.utc(this.editedDate).format(phpDateFormatToMoment(this.dateFormat));
    },

    date: {
      get: function() {
        if ('' === this.editedDate) {
          return '';
        }

        return dayjs.utc(this.editedDate).format('YYYY-MM-DD');
      },
      set: function(date) {
        if ('' === date) {
          this.editedDate = '';
          return;
        }

        this.editedDate = dayjs.utc( date ).toISOString();
      },
    },
  },

  methods: {
    datePicked() {
      this.datepicker = false;
      // Return focus to the textfield to allow 'Enter press as save' functionality
      this.$refs.dateTextField.focus();
    },

    save(close){
      this.$store.commit('updateField', { field: 'date', value: this.editedDate });
      this.$store.commit('updateField', { field: 'name', value: this.editedName });
      this.$store.commit('setCurrentChanged', true);

      close();
    },
    
    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },
};
</script>
